import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`4th October 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now decorate multiple services by path mapping. See the `}<a parentName="p" {...{
            "href": "https://armeria.dev/docs/server-decorator#decorating-multiple-services-by-path-mapping"
          }}>{`documentation`}</a>{` for more information. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/582"
          }}>{`#582`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2040"
          }}>{`#2040`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2057"
          }}>{`#2057`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
// Register vipService and memberService under '/users' path
sb.annotatedService("/users/vip", vipService)
  .annotatedService("/users/members", memberService);

// Decorate all services under '/users' path
sb.decoratorUnder("/users", (delegate, ctx, req) -> {
    if (!authenticate(req)) {
        return HttpResponse.of(HttpStatus.UNAUTHORIZED);
    }
    return delegate.serve(ctx, req);
});
`}</code></pre>
        <p parentName="li">{`You can also use fluent route builder with `}<inlineCode parentName="p">{`routeDecorator()`}</inlineCode>{` to decorate more than one service by path mapping.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Decorate services under '/users' path with fluent route builder
sb.routeDecorator()
  .pathPrefix("/users")
  .build((delegate, ctx, req) -> {
      if (!authenticate(req)) {
          return HttpResponse.of(HttpStatus.UNAUTHORIZED);
      }
      return delegate.serve(ctx, req);
  });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now get the current `}<inlineCode parentName="p">{`HttpRequest`}</inlineCode>{` and `}<inlineCode parentName="p">{`RpcRequest`}</inlineCode>{` from `}<inlineCode parentName="p">{`RequestContext`}</inlineCode>{` so you don’t need to downcast `}<inlineCode parentName="p">{`Request`}</inlineCode>{` to `}<inlineCode parentName="p">{`HttpRequest`}</inlineCode>{` or `}<inlineCode parentName="p">{`RpcRequest`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2089"
          }}>{`#2089`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2120"
          }}>{`#2120`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before:
Request req = ctx.request();
if (req instanceof HttpRequest) {
    RequestHeaders headers = (HttpRequest) req).headers();
}
// After:
RequestHeaders headers = ctx.request().headers();

// Before:
if (req instanceof RpcRequest) {
    String rpcMethod = (RpcRequest) requestContent).method();
}
// After:
// \`rpcRequest()\` method will return \`null\` when the request being handled is not
// an RPC request or not decoded into an RPC request yet.
String rpcMethod = ctx.rpcRequest().method();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set example headers when using `}<inlineCode parentName="p">{`{Annotated,Grpc,Thrift}ServiceRegisrationBean`}</inlineCode>{` for Spring Boot integration. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2100"
          }}>{`#2100`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public AnnotatedServiceRegistrationBean annotatedService() {
    return new AnnotatedServiceRegistrationBean()
               .setServiceName("annotatedService")
               .setService(new AnnotatedService())
               // Add example headers for annotated service
               .addExampleHeaders("x-additional-header", "headerVal")
               .addExampleHeaders("get", "x-additional-header", "headerVal");
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create the following classes using the `}<inlineCode parentName="p">{`builder()`}</inlineCode>{` method instead of their `}<inlineCode parentName="p">{`*Builder`}</inlineCode>{` constructors. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1719"
          }}>{`#1719`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2085"
          }}>{`#2085`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`CircuitBreaker`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`CircuitBreakerHttpClient`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`CircuitBreakerRpcClient`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`DnsAddressEndpointGroup`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`DnsServiceEndpointGroup`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`DnsTextEndpointGroup`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`GrpcService`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`Server`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`RetryingHttpClient`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`RetryingRpcClient`}</inlineCode>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// Before:
Server server = new ServerBuilder()
                      .service("/hello", (ctx, req) -> HttpResponse.of(OK))
                      .build();
// After:
Server server = Server.builder()
                      .service("/hello", (ctx, req) -> HttpResponse.of(OK))
                      .build();
`}</code></pre>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "improvement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvement",
        "aria-label": "improvement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvement`}</h2>
    <ul>
      <li parentName="ul">{`You can use Java 9 version specific `}<inlineCode parentName="li">{`RequestContextAwareFuture`}</inlineCode>{` for `}<a parentName="li" {...{
          "href": "https://www.baeldung.com/java-9-completablefuture"
        }}>{`the recent changes of CompletableFuture API`}</a>{`, thanks to the `}<a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/238"
        }}>{`Multi-Release JAR Files`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1991"
        }}>{`#1991`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2052"
        }}>{`#2052`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2127"
        }}>{`#2127`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ResponseTimeoutException`}</inlineCode>{` is not logged more than once anymore when the response has been timed out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2000"
        }}>{`#2000`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2138"
        }}>{`#2138`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`AbortedStreamException`}</inlineCode>{` while sending long-lived requests with `}<inlineCode parentName="li">{`RetryingHttpClient`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2134"
        }}>{`#2134`}</a></li>
      <li parentName="ul">{`You can now see a warning message when JSON request conversion fails in an annotated service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2041"
        }}>{`#2041`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2131"
        }}>{`#2131`}</a></li>
    </ul>
    <h2 {...{
      "id": "deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecations",
        "aria-label": "deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`AbstractBindingBuilder.pathUnder(String prefix)`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`pathPrefix(String prefix)`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2040"
        }}>{`#2040`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RouteBuilder.prefix(String prefix, ...)`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`pathPrefix(String prefix, ...)`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2040"
        }}>{`#2040`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RouteBuilder.pathWithPrefix(String prefix, String pathPattern)`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`path(String prefix, String pathPattern)`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2040"
        }}>{`#2040`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`new *Builder()`}</inlineCode>{` constructors which are mentioned in 'New Features' have been deprecated in favor of `}<inlineCode parentName="li">{`*.builder()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1719"
        }}>{`#1719`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2085"
        }}>{`#2085`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`armeria-zipkin`}</inlineCode>{` has been removed for further clean-up. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2120"
        }}>{`#2120`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContext.request()`}</inlineCode>{` returns `}<inlineCode parentName="li">{`HttpRequest`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`Request`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2120"
        }}>{`#2120`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`RequestContext.updateRequest()`}</inlineCode>{` always updates an `}<inlineCode parentName="li">{`HttpRequest`}</inlineCode>{`. It returns `}<inlineCode parentName="li">{`void`}</inlineCode>{` now because it never fails, unless `}<inlineCode parentName="li">{`null`}</inlineCode>{` is specified.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`RequestContext.newDerivedContext(Request)`}</inlineCode>{` now requires both `}<inlineCode parentName="li">{`HttpRequest`}</inlineCode>{` and `}<inlineCode parentName="li">{`RpcRequest`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`A default virtual host service can serve any virtual host requests. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2057"
        }}>{`#2057`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2040"
        }}>{`#2040`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Before: If a custom virtual host fails to match the given request to a service, it returns `}<inlineCode parentName="li">{`NOT_FOUND`}</inlineCode>{` status.`}</li>
          <li parentName="ul">{`After: If a custom virtual host fails to match the given request to a service, looking up a default virtual host services to match the request.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`AbstractStreamMessageDuplicator.close()`}</inlineCode>{` does not abort all children `}<inlineCode parentName="li">{`StreamMessage`}</inlineCode>{`s. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2134"
        }}>{`#2134`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You should use `}<inlineCode parentName="li">{`AbstractStreamMessageDuplicator.abort()`}</inlineCode>{` to abort all children `}<inlineCode parentName="li">{`StreamMessage`}</inlineCode>{`s anymore.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`gRPC 1.23.0 -> 1.24.0`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.0.0 -> 4.1.0`}</li>
      <li parentName="ul">{`Jetty 9.4.20.v20190813 -> 9.4.21.v20190926`}</li>
      <li parentName="ul">{`Jackson 2.9.9.20190807 -> 2.10.0`}</li>
      <li parentName="ul">{`Java JWT 3.8.2 -> 3.8.3`}</li>
      <li parentName="ul">{`Micrometer 1.2.1 -> 1.3.0`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['4whomtbts', 'anuraaga', 'codefromthecrypt', 'heowc', 'ikhoon', 'kojilin', 'minwoox', 'moonchanyong', 'sivaalli', 'trustin', 'young891221']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      